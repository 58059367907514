export default {
  white: {
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FFFFFF",
    400: "#FFFFFF",
    500: "#FFFFFF",
    600: "#FFFFFF",
    700: "#FFFFFF",
    800: "#FFFFFF",
    900: "#FFFFFF",
  },
  sickGreen: {
    100: "#DCD273",
    200: "#D6CA5B",
    300: "#D0C345",
    400: "#C9BB33",
    500: "#B7AA2E",
    600: "#A59929",
    700: "#958A25",
    800: "#867C21",
    900: "#79701E",
  },
  staleGrey: {
    100: "#9B9DA1",
    200: "#8C8E93",
    300: "#7F8186",
    400: "#73757A",
    500: "#696A6F",
    600: "#5E5F64",
    700: "#55565A",
    800: "#4D4D51",
    900: "#454549",
  },
  whiteBernat: {
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FFFFFF",
    400: "#FCFBFB",
    500: "#E8E4E1",
    600: "#D4CDC7",
    700: "#C2B8B0",
    800: "#B2A59B",
    900: "#A49488",
  },
  lightGrey: {
    100: "#FDFDFD",
    200: "#E8E5E4",
    300: "#D5D0CD",
    400: "#C4BDB8",
    500: "#B4ACA5",
    600: "#A49B92",
    700: "#968B81",
    800: "#897D72",
    900: "#7B7167",
  },
}

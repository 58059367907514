export default {
  baseStyle: {
    maxW: "1200px",
  },
  variants: {
    "is-section": {
      textAlign: "center",
      mt: "8",
			mb: "8"
    },
  },
}

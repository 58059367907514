exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-activities-page-js": () => import("./../../../src/templates/activities-page.js" /* webpackChunkName: "component---src-templates-activities-page-js" */),
  "component---src-templates-bookings-page-js": () => import("./../../../src/templates/bookings-page.js" /* webpackChunkName: "component---src-templates-bookings-page-js" */),
  "component---src-templates-casa-page-js": () => import("./../../../src/templates/casa-page.js" /* webpackChunkName: "component---src-templates-casa-page-js" */),
  "component---src-templates-confirm-page-js": () => import("./../../../src/templates/confirm-page.js" /* webpackChunkName: "component---src-templates-confirm-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-exteriors-page-js": () => import("./../../../src/templates/exteriors-page.js" /* webpackChunkName: "component---src-templates-exteriors-page-js" */),
  "component---src-templates-general-page-js": () => import("./../../../src/templates/general-page.js" /* webpackChunkName: "component---src-templates-general-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-rebost-page-js": () => import("./../../../src/templates/rebost-page.js" /* webpackChunkName: "component---src-templates-rebost-page-js" */),
  "component---src-templates-rooms-page-js": () => import("./../../../src/templates/rooms-page.js" /* webpackChunkName: "component---src-templates-rooms-page-js" */),
  "component---src-templates-zones-page-js": () => import("./../../../src/templates/zones-page.js" /* webpackChunkName: "component---src-templates-zones-page-js" */)
}

